import styled from '@emotion/styled'
import { lighten } from 'polished'

export const Button = styled.button`
	background: ${(props) => props.theme.buttonColor};
	color: #ffffff;
	padding: 1rem 3rem;
	border: solid 0.2rem ${(props) => lighten(0.1, props.theme.buttonColor)};
	border-radius: 2rem;
	cursor: pointer;
	text-align: center;
	display: inline-block;
	animation: button-growing 2s ease infinite alternate;

	@keyframes button-growing {
		0% {
			border-color: ${(props) => lighten(0.05, props.theme.buttonColor)};
		}

		100% {
			border-color: ${(props) => lighten(0.3, props.theme.buttonColor)};
		}
	}

	&:hover {
		border-color: ${(props) => props.theme.linkColor};
		text-decoration: underline;
		animation: none;
	}
`
