import type { GatsbySSR } from 'gatsby'
import React from 'react'
import { ThemeProvider } from '@emotion/react'
import { themes } from 'src/themes'

export const onRenderBody: GatsbySSR['onRenderBody'] = (
	{ setHeadComponents, setHtmlAttributes, setBodyAttributes },
	pluginOptions
) => {
	setHeadComponents([
		<script
			key='widget-manychat'
			src='//widget.manychat.com/1220402398098821.js'
			defer></script>,
		<script
			key='mccdn'
			src='https://mccdn.me/assets/js/widget.js'
			defer></script>,
		<script
			async
			src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5325472421761595"
			crossOrigin="anonymous"></script>,
	])
}

export const wrapRootElement: GatsbySSR['wrapRootElement'] = ({ element }) => {
	return <ThemeProvider theme={themes.default}>{element}</ThemeProvider>
}
