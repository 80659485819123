import { css } from '@emotion/react'
import { lighten } from 'polished'
import { useTranslation } from 'react-i18next'

export const teckyBlue = '#0B3C85'
export const teckyGreen = '#159391'
export const teckyGreenDarker = '#158481' // to achieve contrast 4.5 for accessibility

const defaultTheme = {
	background: `${teckyBlue} linear-gradient(45deg, ${teckyBlue} 20%, ${teckyGreenDarker})`,
	backgroundAnimation: `${teckyBlue} linear-gradient(45deg,
		${teckyBlue} 0%,
		${teckyGreenDarker} 25%,
		${teckyBlue} 50%,
		${teckyGreenDarker} 73.5%,
		${teckyBlue} 100%)`,
	backgroundStatic: `${teckyBlue}`,
	inverseColor: 'hsla(0,0%,10%,0.9)',
	textColor: 'hsla(0,0%,100%,0.9)',
	headColor: 'hsla(0,0%,100%,0.9)',
	highlightColor: '#ffcc00',
	linkColor: '#ffffff',
	imageFilter: 'brightness(0) invert(1)',
	themeName: 'dark',
	selfPacedColor: '#cc9900',
	fullTimeColor: '#3d1593',
	partTimeColor: '#0C5552',
	freeColor: '#0b3c85',
	buttonColor: teckyGreenDarker,
	accentColor: teckyBlue,
}

const lightTheme = {
	background: `${lighten(0.65, teckyBlue)} linear-gradient(45deg, ${lighten(
		0.65,
		teckyBlue
	)} 20%, ${lighten(0.65, teckyGreen)})`,
	backgroundAnimation: `${lighten(
		0.65,
		teckyBlue
	)} linear-gradient(45deg, ${lighten(0.65, teckyBlue)} 20%, ${lighten(
		0.65,
		teckyGreen
	)})`,
	backgroundStatic: `${lighten(0.65, teckyBlue)}`,
	inverseColor: 'hsla(0,0%,100%,0.9)',
	textColor: 'hsla(0,0%,10%,0.9)',
	headColor: 'hsla(0,0%,10%,0.9)',
	highlightColor: '#ff9900',
	linkColor: '#111111',
	imageFilter: 'none',
	themeName: 'light',
	selfPacedColor: '#ffcc00',
	fullTimeColor: '#3d1593',
	partTimeColor: '#159391',
	freeColor: '#007E8F',
	buttonColor: teckyBlue,
	accentColor: teckyGreen,
}

type TeckyTheme = typeof defaultTheme

export const themes: {
	[T: string]: TeckyTheme
} = {
	default: defaultTheme,
	light: lightTheme,
}

declare module '@emotion/react' {
	export interface Theme extends TeckyTheme {}
}

export const contained = css`
	max-width: 1024px;
	margin: 0 auto;
`

const breakpoints = [576, 768, 992, 1200]
export const minWidth = breakpoints.map((bp) => `@media (min-width: ${bp}px)`)
export const maxWidth = breakpoints.map(
	(bp) => `@media (max-width: ${bp - 0.2}px)`
)

export const responsiveColumn = css`
	${maxWidth[2]} {
		flex-wrap: wrap;
		flex-direction: column;
		gap: 1rem;
	}
`

export const useNumberFormatters = () => {
	const { t } = useTranslation()
	const numberFormatters: {
		[T: string]: Intl.NumberFormat
	} = {
		hkd: new Intl.NumberFormat(t('locale'), {
			style: 'currency',
			currency: 'HKD',
		}),
		usd: new Intl.NumberFormat(t('locale'), {
			style: 'currency',
			currency: 'USD',
		}),
		cad: new Intl.NumberFormat(t('locale'), {
			style: 'currency',
			currency: 'CAD',
		}),
		gbp: new Intl.NumberFormat(t('locale'), {
			style: 'currency',
			currency: 'GBP',
		}),
	}

	return numberFormatters
}
