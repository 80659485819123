import { css, useTheme } from '@emotion/react'
import { Link } from 'gatsby-plugin-react-i18next'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from '@reach/router'
import { maxWidth } from 'src/themes'

export function useCountry() {
	const location = useLocation()

	const [currentCountry, countryPrefix, remainingPath, lastSavedCountry] =
		useMemo(() => {
			let lastSavedCountry =
				(typeof window !== 'undefined' &&
					localStorage?.getItem('country')) ||
				'hk'
			let currentCountry = 'hk'
			let countryPrefix = ''
			const paths = location.pathname.split('/')
			let remainingPath = paths.slice(2, paths.length).join('/')

			if (
				(paths[2] === 'courses' && paths[3] === 'MAP') ||
				(paths[3] === 'courses' && paths[4] === 'MAP')
			) {
				if (paths[2] === 'uk') {
					currentCountry = 'uk'
				}

				if (currentCountry !== 'hk') {
					countryPrefix = '/' + currentCountry
					remainingPath = paths.slice(3, paths.length).join('/')
				}

				if (typeof window !== 'undefined') {
					localStorage?.setItem('country', currentCountry)
				}
				lastSavedCountry = currentCountry
			}

			return [
				currentCountry,
				countryPrefix,
				remainingPath,
				lastSavedCountry,
			]
		}, [location.pathname])

	return [currentCountry, countryPrefix, remainingPath, lastSavedCountry]
}

const CourseMAPLocator = () => {
	const { t } = useTranslation()
	const theme = useTheme()

	const [currentCountry, _, remainingPath] = useCountry()

	return (
		<div
			css={css`
				display: flex;
				gap: 1rem;

				a {
					span {
						margin-right: 0.5rem;
					}
					&.active {
						background-color: ${theme.highlightColor};
						color: ${theme.inverseColor};
					}
					padding: 0.2rem 1rem;
					border-radius: 1rem;
				}

				${maxWidth[1]} {
					flex-direction: column;
					align-items: center;
					gap: 0.5rem;
				}
			`}>
			<Link
				to={`/${remainingPath}`}
				className={currentCountry === 'hk' ? 'active' : ''}>
				<span className='fi fi-hk'></span> {t('countries.hk')}
			</Link>
			<Link
				to={`/uk/${remainingPath}`}
				className={currentCountry === 'uk' ? 'active' : ''}>
				<span className='fi fi-gb'></span> {t('countries.uk')}
			</Link>
		</div>
	)
}

export default CourseMAPLocator
