import { css, useTheme } from '@emotion/react'
import { graphql, StaticQuery } from 'gatsby'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FaTimes } from 'react-icons/fa'
import { maxWidth } from 'src/themes'
import { Button } from './Button'
import { useCountry } from '@pages/{Country.name}/courses/MAP/_locator'

export function ContactForm({
	zohoCode,
	courseCode,
	courseName,
	show,
	type,
	onClose,
}: {
	zohoCode: string
	courseCode: string
	courseName: string
	show: boolean
	type: 'request_syllabus' | 'apply_now'
	onClose: () => void
}) {
	const { t, i18n } = useTranslation()
	const theme = useTheme()
	const [currentCountry, countryPrefix] = useCountry()

	return (
		<StaticQuery
			query={graphql`
				query ContactFormQuery {
					site {
						siteMetadata {
							siteUrl
						}
					}
				}
			`}
			render={(data) => (
				<div
					css={css`
						position: fixed;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						overflow: auto;
						transition: all 0.5s;
						visibility: ${show ? 'visible' : 'hidden'};
						opacity: ${show ? 1 : 0};
						background: rgba(0, 0, 0, 0.5);
						z-index: 15;

						${maxWidth[1]} {
							background: rgba(0, 0, 0, 0.8);
						}

						.close {
							position: absolute;
							color: ${theme.textColor};
							right: 3rem;
							top: 4rem;
							width: 3rem;
							height: 3rem;
							display: flex;
							justify-content: center;
							align-items: center;
							padding: 0;

							${maxWidth[2]} {
								right: 1rem;
								top: 1rem;
							}
						}

						h5 {
							font-size: 2rem;
							font-weight: 600;
							color: ${theme.highlightColor};
							align-self: flex-start;
							margin: 1rem 0;
						}
						h6 {
							font-size: 1rem;
							font-weight: 600;
							align-self: flex-start;
							margin: 1rem 0 3rem;
						}

						> div {
							display: flex;
							justify-content: center;
							align-items: center;
							padding: 2rem 10%;
							min-height: 100vh;
							box-sizing: border-box;

							> form {
								display: flex;
								flex-direction: column;
								align-items: center;
								min-height: min-content;
								background: ${theme.background};
								padding: 4rem;
								position: relative;

								${maxWidth[1]} {
									background: ${theme.background};
									padding: 20px;
								}

								> div {
									position: relative;
									width: 450px;
									margin: 1rem 0;

									${maxWidth[1]} {
										width: 80vw;
									}

									label {
										position: absolute;
										pointer-events: none;
										top: 0;
										left: 0;
										transition: all 0.5s ease;
									}

									input:focus ~ label,
									textarea:focus ~ label,
									select:focus ~ label,
									input:not(:placeholder-shown) ~ label,
									textarea:not(:placeholder-shown) ~ label,
									select:not(:placeholder-shown) ~ label {
										font-size: 0.7rem;
										color: ${theme.highlightColor};
										top: -20px;
									}

									input:invalid:not(:placeholder-shown)
										~ label,
									textarea:invalid:not(:placeholder-shown)
										~ label,
									select:invalid:not(:placeholder-shown)
										~ label {
										font-size: 0.7rem;
										color: red !important;
										top: -20px;
									}

									input,
									textarea,
									select {
										width: 100%;
										background: transparent;
										border: 0;
										border-bottom: solid 1px #efefef;
										color: #efefef;
									}
									option {
										background: lightcyan;
										color: darkblue;
									}
								}
							}
						}
					`}>
					<div>
						<form
							onClick={(e) => e.stopPropagation()}
							action={
								type === 'request_syllabus'
									? 'https://proxy.tecky.io/request-syllabus'
									: 'https://proxy.tecky.io/apply-now'
							}
							method='POST'
							acceptCharset='UTF-8'>
							<input
								type='hidden'
								name='returnURL'
								value={`${data.site.siteMetadata.siteUrl}/${i18n.language}${countryPrefix}/courses/${courseCode}/submission`}
							/>
							<input
								type='hidden'
								name='Lead Status'
								value={
									type === 'request_syllabus'
										? 'Requested Syllabus'
										: 'Applied'
								}
							/>
							<input
								type='hidden'
								name='LEADCF3'
								value={
									currentCountry === 'hk'
										? zohoCode
										: `${zohoCode} (${currentCountry.toUpperCase()})`
								}
							/>

							<Button
								className='close'
								type='button'
								onClick={onClose}>
								<FaTimes size={24} />
							</Button>

							<h5>
								{type === 'request_syllabus'
									? t('request_syllabus')
									: t('apply_now')}
								<br />
								<small>{courseName}</small>
							</h5>
							<h6>
								{type === 'request_syllabus'
									? t('check_after_submission')
									: t('contact_after_submission')}
							</h6>

							<div>
								<input
									required
									type='text'
									maxLength={40}
									name='First Name'
									placeholder=' '
									id='firstName'
								/>
								<label htmlFor='firstName'>
									{t('First Name')}
								</label>
							</div>
							<div>
								<input
									required
									type='text'
									maxLength={80}
									name='Last Name'
									placeholder=' '
									id='lastName'
								/>
								<label htmlFor='lastName'>
									{t('Last Name')}
								</label>
							</div>
							<div>
								<input
									required
									type='email'
									maxLength={100}
									name='Email'
									placeholder=' '
									id='email'
								/>
								<label htmlFor='email'>{t('Email')}</label>
							</div>
							<div>
								<input
									required
									type='tel'
									maxLength={30}
									name='Phone'
									placeholder=' '
									id='phone'
								/>
								<label htmlFor='phone'>{t('Phone')}</label>
							</div>
							<div>
								<select required name='City' id='city'>
									<option value='Hong Kong'>
										🇭🇰 {t('Hong Kong')}
									</option>
									<option value='London'>
										🇬🇧 {t('London')}
									</option>
									<option value='Manchester'>
										🇬🇧 {t('Manchester')}
									</option>
									<option value='UK - Others'>
										🇬🇧 {t('UK - Others')}
									</option>
									<option value='Toronto'>
										🇨🇦 {t('Toronto')}
									</option>
									<option value='Vancouver'>
										🇨🇦 {t('Vancouver')}
									</option>
									<option value='Canada - Others'>
										🇨🇦 {t('Canada - Others')}
									</option>
								</select>
								<label htmlFor='city'>{t('City')}</label>
							</div>
							<div>
								<input
									list='LEADCF1'
									name='LEADCF1'
									id='reason'
								/>
								<datalist id='LEADCF1'>
									<option value='Career Change'>
										{t('Career Change')}
									</option>
									<option value='Self Improvement'>
										{t('Self Improvement')}
									</option>
									<option value='Build My Startup'>
										{t('Build My Startup')}
									</option>
									<option value='Interested In Tech'>
										{t('Interested In Tech')}
									</option>
								</datalist>
								<label htmlFor='reason'>
									{t('Reason of Joining')}
								</label>
							</div>
							<Button type='submit'>
								{type === 'request_syllabus'
									? t('get_the_syllabus')
									: t('apply_now')}
							</Button>
						</form>
					</div>
				</div>
			)}
		/>
	)
}
